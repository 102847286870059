<template>
    <div class="card card-custom">
        <div class="card-header">
            <h3 class="card-title">{{title}}</h3>
            <button type="button" class="close" @click="cancelForm()">
                <i aria-hidden="true" class="ki ki-close"></i>
            </button>
        </div>

        <!--begin::Form-->

            <div class="card-body" style="max-height:500px;overflow:auto;">

                <template v-if="data_form.operate == 'sku_add'">
                    <div class="form-group row">
                        <label class="col-lg-3 col-form-label">前缀:</label>
                        <div class="col-lg-6">
                            <input type="text" class="form-control" placeholder="请输入产品SKU前缀"
                                   v-model="data_form.prevname">
                            <span class="form-text text-muted"></span>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-lg-3 col-form-label">后缀:</label>
                        <div class="col-lg-6">
                            <input type="text" class="form-control" placeholder="请输入权限标题" v-model="data_form.nextname">
                            <span class="form-text text-muted"></span>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="form-group row">
                        <label class="col-lg-3 col-form-label">数值:</label>
                        <div class="col-lg-6">
                            <input type="text" class="form-control" placeholder="请输入批量添加的数值"
                                    v-model="data_form.num"
                                    @input="oninput"
                            >
                            <span class="form-text text-muted"></span>
                        </div>
                    </div>
                </template>
            </div>

            <div class="card-footer">
                <button class="btn btn-primary mr-2" type="button" @click="saveForm()">保存</button>
                <button class="btn btn-secondary" type="reset" @click="cancelForm()">取消</button>
            </div>

        <!--end::Form-->
    </div>
</template>
<script>
    import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
    import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
    import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
    import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
    import KTUtil from "@/assets/js/components/util";

    export default {
        name: "batch_operate_dialog",
        props: {
            title: String,
            dialog_data: Object
        },
        created:function() {

        },
        components :{

        },
        data() {
            return {
                data_form:{
                    operate: this.dialog_data.operate,
                    prevname: '',
                    nextname: '',
                    num: '0.00'
                }
            }
        },
        mounted() {


        },
        methods: {
            cancelForm(){
                this.$emit('opeareResultHandler', 0);
            },
            saveForm() {
                this.$emit('opeareResultHandler', this.data_form);
            },
            oninput(e){
                e.target.value = (e.target.value.match(/^\d*(\.?\d{0,2})/g)[0]) || null;
            }
        }
    }
</script>
