<template>
    <v-dialog v-model="dialog_show" persistent width="1400px">
        <div class="card card-custom">
            <div class="card-header">
                <h3 class="card-title">图片裁剪</h3>
                <button type="button" class="close"  @click="dialog_show = false">
                    <i aria-hidden="true" class="ki ki-close"></i>
                </button>
            </div>
            <div class="card-body" style="max-height:700px;overflow-y: auto;">
                <div class="row">
                    <div class="col-md-8">
                        <!-- <img :src="imgSrc" /> -->
                        <div class="img-cropper">
                            <vue-cropper
                                    ref="cropper"
                                    :src="imgSrc"
                                    preview=".preview"
                                    class="cropper-min-height"

                            />
                        </div>
                        <div class="actions">
                            <b-button-group vertical class="mr-3 mb-3">
                                <b-button @click="zoom(0.2)">
                                    <i class="fa fa-search-plus" aria-hidden="true"></i>
                                </b-button>
                            </b-button-group>
                            <b-button-group vertical class="mr-3 mb-3">
                                <b-button @click="zoom(-0.2)">
                                    <i class="fa fa-search-minus" aria-hidden="true"></i>
                                </b-button>
                            </b-button-group>

                            <b-button-group vertical class="mr-3 mb-3">
                                <b-button @click="move(-10, 0)">
                                    <i class="fa fa-caret-left" aria-hidden="true"></i>
                                </b-button>
                            </b-button-group>
                            <b-button-group vertical class="mr-3 mb-3">
                                <b-button @click="move(10, 0)">
                                    <i class="fa fa-caret-right" aria-hidden="true"></i>
                                </b-button>
                            </b-button-group>
                            <b-button-group vertical class="mr-3 mb-3">
                                <b-button @click="move(0, -10)">
                                    <i class="fa fa-caret-up" aria-hidden="true"></i>
                                </b-button>
                            </b-button-group>
                            <b-button-group vertical class="mr-3 mb-3">
                                <b-button @click="move(0, 10)">
                                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                                </b-button>
                            </b-button-group>
                            <b-button-group vertical class="mr-3 mb-3">
                                <b-button @click="rotate(90)">
                                    <i class="fa fa-share" aria-hidden="true"></i>
                                </b-button>
                            </b-button-group>
                            <b-button-group vertical class="mr-3 mb-3">
                                <b-button @click="rotate(-90)">
                                    <i class="fa fa-reply" aria-hidden="true"></i>
                                </b-button>
                            </b-button-group>

                            <b-button @click="cropImage" class="mr-3 mb-3">
                                <i class="fa fa-crop" aria-hidden="true"></i>
                            </b-button>
                            <b-button @click="reset" class="mr-3 mb-3">
                                <i class="fa fa-arrows-alt" aria-hidden="true"></i>
                            </b-button>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div>
                            <p>源图片</p>
                            <div class="w-100 cropped-img mt-2 preview secondary"/>
                        </div>
                        <div style="margin-top:20px">
                            <p>处理后图片</p>
                            <div class="cropped-image secondary">
                                <img v-if="cropImg" :src="cropImg" />
                                <div v-else class="crop-placeholder" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-footer">
                <button class="btn btn-primary mr-2" ref="data_form_submit" type="button" @click="saveImage()">保存</button>
                <button class="btn btn-secondary" type="reset"  @click="dialog_show = false">取消</button>
            </div>
        </div>
    </v-dialog>
</template>
<script>
    import VueCropper from "vue-cropperjs";
    import "cropperjs/dist/cropper.css";
    import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
    import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
    import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
    import KTUtil from "@/assets/js/components/util";
    import {upload, getRemoteImage} from '@/api/image';

    export default {
        name: "cropper_dialog",
        props: {
            show: Number,
            image: String,
            dir: String,
        },
        watch: {
            'show': function (val) {
                if(this.show){
                    this.dialog_show = true;
                } else {
                    this.dialog_show = false;
                }
            },
            'image': function (val) {
                var _this = this;
                //console.log(_this.image);
                _this.file_name = _this.getFileName(_this.image);
                _this.imgSrc = _this.image;
            },
            'dialog_show': function(val) {
                this.$emit('opeareResultHandler', 0);
            }
        },

        created:function() {

        },
        components :{
            VueCropper
        },
        data() {
            return {
                imgSrc: '',
                cropImg: "",
                dialog_show: false,
                file_name: ''
            }
        },
        mounted() {


        },
        methods: {
            cropImage() {
                // get image data for post processing, e.g. upload or setting image src
                //console.log(this.$refs.cropper.crop());
                this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
            },
            move(offsetX, offsetY) {
                this.$refs.cropper.move(offsetX, offsetY);
            },
            reset() {
                this.$refs.cropper.reset();
            },
            rotate(deg) {
                this.$refs.cropper.rotate(deg);
            },
            setImage(e) {
                const file = e.target.files[0];
                this.fileExtension = file.name.split(".").pop();
                if (file.type.indexOf("image/") === -1) {
                    alert("Please select an image file");
                    return;
                }

                if (typeof FileReader === "function") {
                    const reader = new FileReader();

                    reader.onload = event => {
                        this.imgSrc = event.target.result;
                        // rebuild cropperjs with the updated source
                        this.$refs.cropper.replace(event.target.result);
                    };

                    reader.readAsDataURL(file);
                } else {
                    alert("Sorry, FileReader API not supported");
                }
            },
            showFileChooser() {
                this.$refs.input.click();
            },
            download() {
                if (this.cropImg) {
                    var extention = this.fileExtension;
                    var href = this.cropImg;
                    var link = document.createElement("a");
                    link.addEventListener(
                        "click",
                        function() {
                            link.href = href;
                            link.download = "cropper-img." + extention;
                            // console.log(link.download);
                        },
                        false
                    );
                    link.click();
                } else {
                    alert("Please, crop image before download it");
                }
            },
            zoom(percent) {
                this.$refs.cropper.relativeZoom(percent);
            },
            saveImage() {

                //按钮加载动画
                const submitButton = this.$refs["data_form_submit"];
                submitButton.classList.add("spinner", "spinner-light", "spinner-right");

                //上传图片
                if(this.cropImg) {
                    var _this = this;
                    let formdata = new FormData()
                    formdata.set('file', this.dataURLtoFile(this.cropImg, this.file_name));
                    formdata.set('dir', this.dir);

                    upload(formdata).then(function (res) {
                        if (res && res.url) {
                            _this.$emit('opeareResultHandler', res.url);
                            _this.dialog_show = false;
                        }

                        submitButton.classList.remove(
                            "spinner",
                            "spinner-light",
                            "spinner-right"
                        );
                    });
                } else {
                    KTUtil.toast(_this, '请确认裁剪图片', 'warning');
                    submitButton.classList.remove(
                        "spinner",
                        "spinner-light",
                        "spinner-right"
                    );
                }
            },
            //将base64转换为文件
            dataURLtoFile: function (dataurl, filename) {
                var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n);
                }
                return new File([u8arr], filename, {type: mime});
            },
            getFileName: function (filepath) {
                if (filepath != "") {
                    var names = filepath.split("\\");
                    return names[names.length - 1];
                }
            },
            imgTrick(url) {
                var _this = this;
                getRemoteImage({'file_url': url}).then(function(res){
                    if(res && res.code == 200){
                        _this.imgSrc = res.url;
                    }
                });
            },
        }
    }
</script>
<style>
    input[type="file"] {
        display: none;
    }

    .content {
        display: flex;
        justify-content: space-between;
    }

    .actions {
        margin-top: 1rem;
    }

    .preview-area p:last-of-type {
        margin-top: 1rem;
    }

    .preview {
        width: 100%;
        height: calc(372px * (9 / 16));
        overflow: hidden;
    }

    .cropped-image img {
        max-width: 100%;
    }

    .cropper-bg {
        background-repeat: repeat;
    }

    .cropper-min-height {
        max-height: 580px;
    }

    .cropped-img img{
        max-width: 400px;
        max-height:200px;
    }


    .cropped-image img{
        max-width: 400px;
        max-height:200px;
    }
    .cropped-image {
        text-align: center;
        padding:10px;
    }
</style>
