<template>
    <v-app style="background: transparent" :class="{}">
        <!-- begin:: Content Head -->
        <KTSubheader
                v-if="nav.subheaderDisplay"
                v-bind:breadcrumbs="nav.breadcrumbs"
                v-bind:title="nav.pageTitle"
        />
        <!-- end:: Content Head -->
        <div class="d-flex flex-column-fluid">
            <!--begin::Container-->
            <div class="container">

                <form class="form fv-plugins-bootstrap fv-plugins-framework" id="dataForm" novalidate="novalidate">

                    <!--begin::Card-->
                    <div class="card card-custom example example-compact">
                        <div class="card-header">
                            <h3 class="card-title">基础信息</h3>
                        </div>
                        <!--begin::Form-->

                        <div class="card-body">
                            <div class="form-group row fv-plugins-icon-container">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">所属平台 <span class="text-danger">*</span>:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <b-form-select class="form-control" v-model="product.platform" :options="plat_form_list"  name="platform">

                                    </b-form-select>
                                </div>
                            </div>

                            <div class="form-group row fv-plugins-icon-container">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">所属站点 <span class="text-danger">*</span>:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <b-form-select class="form-control" v-model="product.site_id" :options="site_list"  name="site_id">

                                    </b-form-select>
                                </div>
                            </div>

                            <div class="form-group row fv-plugins-icon-container">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">产品标题 <span class="text-danger">*</span>:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <input  type="text"
                                            v-model="product.product_title"
                                            name="product_title"
                                            placeholder=""
                                            autocomplete="off"
                                            spellcheck="false"
                                            dir="auto"
                                            class="form-control tt-input"
                                            style="position: relative; vertical-align: top; background-color: transparent;">
                                </div>
                            </div>

                            <div class="form-group row fv-plugins-icon-container">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">产品分类:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">

                                    <treeselect
                                            :options="category_list"
                                            :default-expand-level="1"
                                            placeholder="请选择产品分类"
                                            v-model="product.category_id"
                                            @select="selectCategory"
                                    />
                                </div>
                            </div>


                            <div class="form-group row fv-plugins-icon-container">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">中文标题:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <input  type="text"
                                            v-model="product.product_title_cn"
                                            name="product_title_cn"
                                            placeholder=""
                                            autocomplete="off"
                                            spellcheck="false"
                                            dir="auto"
                                            class="form-control tt-input"
                                            style="position: relative; vertical-align: top; background-color: transparent;">

                                </div>
                            </div>

                            <div class="form-group row fv-plugins-icon-container">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">产品地址:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <input  type="text"
                                            v-model="product.product_url"
                                            name="product_url"
                                            placeholder=""
                                            autocomplete="off"
                                            spellcheck="false"
                                            dir="auto"
                                            class="form-control tt-input"
                                            style="position: relative; vertical-align: top; background-color: transparent;">
                                </div>
                            </div>

                            <div class="form-group row">
                                <label class="col-xl-3 col-lg-3 col-form-label text-right">产品图片 <span class="text-danger">*</span>:</label>
                                <div class="col-lg-9 col-xl-9">

                                    <input type="hidden" v-model="product.images" name="images"/>
                                    <input type="file" ref="product_image_upload" multiple="multiple" style="display:none;" @change="productImageUploadChange()"/>

                                    <div id="superbox-image-list">

                                        <div class="image-input image-input-outline mr-10"
                                             v-for="(image, idx) in product.images"
                                             v-bind:key="idx"
                                             :style="{backgroundImage: 'url('+ image +')'}">
                                            <div class="image-input-wrapper"
                                                 :style="{backgroundImage: 'url('+ image +')'}">
                                            </div>
                                            <span class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                                  data-action="remove"
                                                  data-toggle="tooltip"
                                                  title="删除"
                                                  data-original-title="删除"
                                                  @click="removeProductImage(image)"
                                            >
                                                <i class="ki ki-bold-close icon-xs text-muted"></i>
                                            </span>

                                            <span class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" style="right: 20px;"
                                                  data-action="remove"
                                                  data-toggle="tooltip"
                                                  title="裁剪"
                                                  data-original-title="裁剪"
                                                  @click="cropperProductImage(image, idx)"
                                            >
                                                <i class="ki ki-drag icon-xs text-muted"></i>
                                            </span>
                                        </div>

                                    </div>
                                    <div class="image-input image-input-outline">
                                        <div class="addimg" @click="productImageUpload()">
                                            <i class="fa fa-plus-circle"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="separator separator-dashed my-10"></div>

                            <div class="form-group row">
                                <label class="col-form-label text-right col-lg-3 col-sm-12">产品价格:</label>
                                <div class=" col-lg-3 col-md-9 col-sm-12">
                                    <div class="form-group validated">
                                        <div class="input-group">
                                            <input type="text" class="form-control" value="price" v-model="product.price" @input="onFloatInput"
                                                   placeholder="0.00">
                                            <div class="input-group-append">
                                                <span class="input-group-text">USD</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--end::Form-->
                    </div>
                    <!--end::Card-->

                    <!--begin::Card-->
                    <div class="card card-custom example example-compact mt-10">
                        <div class="card-header">
                            <h3 class="card-title">售卖属性</h3>
                        </div>
                        <div class="card-body">
                            <div class="form-group row">
                                <input type="hidden" name="sku" v-model="product.sku"/>
                                <label class="col-xl-3 col-lg-3 col-form-label text-right">变种主题 <span class="text-danger">*</span>:</label>
                                <div class="col-md-4 col-sm-12  mt-3">
                                    <div class="checkbox-inline">
                                        <label class="checkbox pr-5"
                                               v-for="(attr, idx) in attr_keys"
                                               :key="idx"
                                        >
                                            <input type="checkbox" name="attr_value" v-model="select_attr_keys" :value="attr.name">
                                            <span></span>{{attr.name}}
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-12">
                                    <div class="input-group">
                                        <input type="text" ref="add_attr_key_input" class="form-control" placeholder="自定义主题">
                                        <div class="input-group-append">
                                            <button class="btn btn-primary" type="button" @click="addAttrKey()">添加</button>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="form-group row"
                                 v-for="(attr, idx) in select_attr_keys"
                                 :key="idx"
                            >
                                <label class="col-xl-3 col-lg-3 col-form-label text-right">{{attr}}:</label>
                                <div class="col-md-4 col-sm-12">
                                    <div class="form-group alert alert-custom alert-default">
                                        <div class="checkbox-inline">
                                            <template  v-for="(attr_value, idx) in attr_values">
                                                <label class="checkbox"
                                                       :key="idx"
                                                       v-if="attr_value.pname == attr"
                                                >
                                                    <input type="checkbox" v-model="select_attr_values[attr]" :name="'select_attr_values['+ attr +']'" :value="attr_value.name">
                                                    <span></span>
                                                    {{attr_value.name}}
                                                </label>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-6">
                                    <div class="input-group">
                                        <input type="text" class="form-control" :id="'add_attr_value_input_'+ attr" placeholder="添加属性">
                                        <div class="input-group-append">
                                            <button class="btn btn-primary" type="button" @click="addAttrValue(attr)">添加</button>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="form-group table-responsive">
                                <table class="table common-th ">
                                    <thead>
                                    <tr class="alert alert-light">
                                        <th scope="col" width="10%">
                                            图片
                                        </th>
                                        <th scope="col">SKU
                                            <div class="dropdown">
                                                <button type="button" class="btn btn-text-primary btn-hover-light-primary" @click="batchProductSku()">(一键生成)</button>
                                            </div>

                                        </th>
                                        <template v-for="(key, idx) in select_attr_keys">
                                            <th
                                                    :key="idx"
                                                    v-if="select_attr_values[key].length > 0"
                                                    scope="col" width="6%">
                                                {{key}}
                                            </th>
                                        </template>
                                        <th scope="col">原价
                                            <div class="dropdown">
                                                <button type="button" class="btn btn-text-primary btn-hover-light-primary" @click="batchProductPrice()">(批量)</button>
                                            </div>
                                        </th>
                                        <th scope="col">折扣
                                            <div class="dropdown">
                                                <button type="button" class="btn btn-text-primary btn-hover-light-primary" @click="batchProductDiscount()">(批量)</button>
                                            </div>
                                        </th>
                                        <th scope="col">实价
                                            <div class="dropdown">
                                                <button type="button" class="btn btn-text-primary btn-hover-light-primary" @click="batchProductNetPrice()">(批量)</button>
                                            </div>
                                        </th>
                                        <th scope="col" width="6%">操作</th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    <template v-for="(item, idx) in product.sku">
                                        <tr :key="idx">
                                            <td scope="row">
                                                <template v-if="item.image_url == ''">
                                                    <input type="file" style="display:none;" :id="'sku_image_upload_' + idx" @change="skuImageUploadChange(idx)"/>
                                                    <a href="javascript:void(0);" class="btn btn-icon btn-light-primary btn-circle" @click="skuImageUploadClick(idx)">
                                                        <i class="flaticon-photo-camera"></i>
                                                    </a>
                                                </template>
                                                <template v-else>
                                                    <input type="file" style="display:none;" :id="'sku_image_upload_' + idx" @change="skuImageUploadChange(idx)"/>
                                                    <a href="javascript:;" @click="skuImageUploadClick(idx)">
                                                        <img :src="item.image_url" width="40" height="40">
                                                    </a>
                                                </template>
                                            </td>
                                            <td><input type="text" name="sku" v-model="product.sku[idx].sku"  class="form-control" placeholder=""></td>
                                            <td v-for="(attr, idx) in item.attr_info"
                                                :key="idx">
                                                {{attr}}
                                            </td>
                                            <td><input type="text" @input="onFloatInput" name="price"  v-model="product.sku[idx].price" class="form-control" placeholder=""></td>
                                            <td><input type="text" @input="onFloatInput" class="form-control" placeholder="" v-model="product.sku[idx].discount" name="discount"></td>
                                            <td><input type="text" @input="onFloatInput" name="net_price"  v-model="product.sku[idx].net_price" class="form-control" placeholder=""></td>
                                            <td>
                                                <a href="javascript:;" class="btn btn-sm btn-clean btn-icon" title="Delete" @click="deleteSkuItem(idx)"><i class="flaticon-delete"></i></a>
                                            </td>
                                        </tr>
                                    </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <!--end::Card-->

                    <div class="card card-custom example example-compact mt-10">
                        <div class="card-header">
                            <h3 class="card-title">产品描述</h3>
                        </div>
                        <div class="card-body">
                            <TEditor ref="editor" v-model="product.description"/>
                        </div>
                    </div>

                    <div class="card card-custom example example-compact mt-10">
                        <div class="card-header">
                            <h3 class="card-title">报关信息</h3>
                        </div>
                        <div class="card-body">
                            <div class="form-group row ">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">中文报关:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <input class="form-control tt-input"
                                           type="text"
                                           name="chinese_declaration"
                                           v-model="product.chinese_declaration"
                                           placeholder=""
                                           autocomplete="off"
                                           spellcheck="false"
                                           dir="auto"
                                           style="position: relative; vertical-align: top; background-color: transparent;">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">英文报关:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <input class="form-control tt-input"
                                           type="text"
                                           name="english_declaration"
                                           v-model="product.english_declaration"
                                           placeholder=""
                                           autocomplete="off"
                                           spellcheck="false"
                                           dir="auto"
                                           style="position: relative; vertical-align: top; background-color: transparent;">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-form-label text-right col-lg-3 col-sm-12">报关价格:</label>
                                <div class=" col-lg-3 col-md-9 col-sm-12">
                                    <div class="form-group validated">
                                        <div class="input-group">
                                            <input type="text" class="form-control" name="declaration_price" v-model="product.declaration_price" @input="onFloatInput"
                                                   placeholder="0.00">
                                            <div class="input-group-append">
                                                <span class="input-group-text">USD</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-form-label text-right col-lg-3 col-sm-12">报关重量:</label>
                                <div class=" col-lg-3 col-md-9 col-sm-12">
                                    <div class="form-group validated">
                                        <div class="input-group">
                                            <input type="text" class="form-control" name="declaration_weight" v-model="product.declaration_weight" @input="onFloatInput"
                                                   placeholder="0.00">
                                            <div class="input-group-append">
                                                <span class="input-group-text">g</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">海关编码:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <input class="form-control tt-input"
                                           type="text"
                                           name="customs_encoding"
                                           v-model="product.customs_encoding"
                                           placeholder=""
                                           autocomplete="off"
                                           spellcheck="false"
                                           dir="auto"
                                           style="position: relative; vertical-align: top; background-color: transparent;">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">危险运输品:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <input class="form-control tt-input"
                                           type="text"
                                           name="dangerous_transport_goods"
                                           v-model="product.dangerous_transport_goods"
                                           placeholder=""
                                           autocomplete="off"
                                           spellcheck="false"
                                           dir="auto"
                                           style="position: relative; vertical-align: top; background-color: transparent;">
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--begin::Card-->
                    <div class="card card-custom example example-compact mt-10">
                        <div class="card-header">
                            <h3 class="card-title">SEO信息</h3>
                        </div>
                        <div class="card-body">
                            <div class="form-group row ">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">Title:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <input class="form-control tt-input"
                                           type="text"
                                           name="SEO_title"
                                           v-model="product.SEO_title"
                                           placeholder=""
                                           autocomplete="off"
                                           spellcheck="false"
                                           dir="auto"
                                           style="position: relative; vertical-align: top; background-color: transparent;">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">Keyword:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <input class="form-control tt-input"
                                           type="text"
                                           name="SEO_keyword"
                                           v-model="product.SEO_keyword"
                                           placeholder=""
                                           autocomplete="off"
                                           spellcheck="false"
                                           dir="auto"
                                           style="position: relative; vertical-align: top; background-color: transparent;">
                                </div>
                            </div>

                            <div class="form-group row ">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">Description:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                <textarea class="form-control" v-model="product.SEO_desc"
                                          name="SEO_desc" rows="5">
                                </textarea>
                                </div>
                            </div>

                            <div class="card-footer">
                                <div class="row">
                                    <div class="col-lg-9 ml-lg-auto">
                                        <button ref="data_form_submit" type="submit" class="btn btn-primary mr-2"> 保存产品</button>
                                        <a href="/product" class="btn btn-light-primary">返回</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--end::Card-->
                </form>
            </div>
        </div>

        <v-dialog v-model="dialog.show" persistent max-width="600px">
            <component
                    :key="dialog.id"
                    :title="dialog.title"
                    :visible="dialog.show"
                    :is="dialog.view"
                    :dialog_data="dialog.data"
                    @opeareResultHandler="opeareResultHandler"></component>
        </v-dialog>

        <!-- 图片裁剪组件 -->
        <Cropper
                :show="crop_data.id"
                :image="crop_data.image"
                dir="/product"
                @opeareResultHandler="productImageChange"
        ></Cropper>

    </v-app>
</template>

<style lang="css">
    @import "~@/assets/css/common.css";

</style>
<script>
    import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
    import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
    import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
    import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
    import KTSubheader from "@/view/layout/subheader/Subheader.vue";
    import KTUtil from "@/assets/js/components/util";
    import Sortable from 'sortablejs';
    import TEditor from '@/view/content/tinymce/Index';
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import Treeselect from '@riophae/vue-treeselect'

    import {getSiteListByPlatForm, updateProudct, getProduct, getCategory} from '@/api/product';
    import {upload} from '@/api/image';
    import batchAdd from '@/view/pages/product/BatchAdd';
    import Cropper from '@/view/content/image/Cropper.vue';
    export default {
        name: "update_product_view",
        components: {
            KTSubheader,
            TEditor,
            Treeselect,
            Cropper
        },
        created: function(){
            var _this = this;
            getProduct({'id': _this.$route.query.id}).then(function(res) {
                if (res.response) {
                    _this.product = res.response;

                    //处理图片
                    var images =  res.response.images;
                    var array = [];
                    if(images && images.length > 0){
                        images.forEach(function(value){
                            array.push(value.image_url);
                        });
                    }
                    _this.product.images = array;

                    //处理sku
                    var sku = res.response.sku;
                    var array = [];
                    //_this.product.sku = [];
                    if(sku && sku.length > 0){
                        sku.forEach(function(value){
                            var discount = 0.00;
                            if(value['price'] > 0){
                                discount = (value['net_price'] / value['price']).toFixed(2);
                            }
                            var attr_info = JSON.parse(value['attr_info']);
                            var data = {
                                'sku': value['sku'],
                                'image_url': value['image_url']?value['image_url']:'',
                                'price': value['price'],
                                'net_price': value['net_price'],
                                'discount': discount,
                                'attr_info': attr_info
                            };
                            array.push(data);

                            for(var key in attr_info){

                                var value = attr_info[key];

                                //重组主题数据
                                if(!KTUtil.isHasAtrr(_this.attr_keys, key)){
                                    _this.attr_keys.push({'name': key});
                                }
                                if(!KTUtil.isHasAtrr(_this.attr_values, value)){
                                    _this.attr_values.push({'name': value, 'pname': key});
                                }

                                //重组SKU属性选择数据
                                if(_this.select_attr_keys.indexOf(key) == -1){
                                    _this.select_attr_keys.push(key);
                                }
                                if(_this.select_attr_values[key]){
                                    if(_this.select_attr_values[key].indexOf(value) == -1){
                                        _this.select_attr_values[key].push(value);
                                    }
                                } else {
                                    _this.$set(_this.select_attr_values, key, []);
                                    _this.select_attr_values[key].push(value);
                                }
                            }
                        });
                        _this.product.sku = array;

                    }
                }
            });


        },
        watch: {
            'product.images': function(val){
                this.initSortable();
            },
            select_attr_keys: {
                handler (val, oldval) {
                    /*
                    if(val.length > 3){
                        this.select_attr_keys = oldval;
                        KTUtil.toast(this, '提示', '最多选择三个主题', 'danger');
                        return;
                    }
                    */
                    for(var i = 0;i < this.select_attr_keys.length;i++){
                        var key = this.select_attr_keys[i];

                        if(this.select_attr_values[key] == null || this.select_attr_values[key] == undefined){
                            this.$set(this.select_attr_values, key, []);
                        }
                    }

                    //判断取消选择
                    if(oldval.length > val.length){
                        for (var i = 0;i < oldval.length;i++) {
                            if(val.indexOf(oldval[i]) === -1){
                                var key = oldval[i];
                                this.$set(this.select_attr_values, key, []);
                            }
                        }


                    }
                },
                deep: true
            },
            select_attr_values: {
                handler (val, oldval) {
                    this.createSkuList();
                },
                deep: true
            },
            'product.site_id': function(){
                var _this = this;
                getCategory({'site_id': _this.product.site_id}).then(function(res){
                   if(res && res.response){
                       _this.category_list = res.response;
                   }
                });
            },
            'product.sku': {
                handler (val, oldval) {
                    for(var i = 0;i < this.product.sku.length;i++){
                        var net_price = this.product.sku[i].price * this.product.sku[i].discount;
                        this.product.sku[i].net_price =  net_price.toFixed(2);
                    }
                },
                deep: true
            },
            'product.platform': function(){
                var _this = this;
                getSiteListByPlatForm({'site_platform': _this.product.platform}).then(function(res) {
                    if(res && res.response){
                        var list = [];
                        res.response.forEach(function(value){
                            list.push({'value': value.id, 'text': value.site_name});
                        });
                        _this.site_list = list;
                    }
                });
            }
        },
        data() {
            return {
                nav: {
                    subheaderDisplay: true,
                    breadcrumbs : [{'id':'', 'route':'/product', 'title':'产品管理'}, {'id':'', 'route':'/product/updateproduct', 'title':'修改产品'}],
                    pageTitle: '产品'
                },
                plat_form_list: KTUtil.getPlatForm(),
                site_list: [],
                category_list: [],
                product: {
                    id: this.$route.query.id,
                    site_id: '',
                    platform: '',
                    product_title: '',
                    product_title_cn: '',
                    product_url: '',
                    category_id: '',
                    category_name: '',
                    images: [],
                    price: '0.00',
                    sku: [],
                    description: '',
                    SEO_title: '',
                    SEO_keyword: '',
                    SEO_desc: '',
                    chinese_declaration: '',
                    english_declaration: '',
                    declaration_weight: '0.00',
                    declaration_price: '0.00',
                    customs_encoding: '',
                    dangerous_transport_goods: ''

                },
                attr_keys: [],
                attr_values: [],
                select_attr_keys: [],
                select_attr_values: {

                },
                dialog: {
                    id: 0,
                    show: false,
                    title: '',
                    view: null,
                    data: null
                },
                crop_data: {
                    id: 0,
                    show: false,
                    image: '',
                }
            }
        },
        mounted() {
            const data_form = KTUtil.getById("dataForm");

            //表单数据验证
            this.fv = formValidation(data_form, {
                fields: {
                    platform: {
                        validators: {
                            notEmpty: {
                                message: "所属平台不能为空"
                            }
                        }
                    },
                    site_id: {
                        validators: {
                            notEmpty: {
                                message: "所属站点不能为空"
                            }
                        }
                    },
                    product_title: {
                        validators: {
                            notEmpty: {
                                message: "产品标题不能为空"
                            }
                        }
                    },
                    images: {
                        validators: {
                            notEmpty: {
                                message: "产品图片不能为空"
                            }
                        }
                    },
                    sku: {
                        validators: {
                            notEmpty: {
                                message: "产品SKU不能为空"
                            }
                        }
                    },
                },
                plugins: {
                    trigger: new Trigger(),
                    submitButton: new SubmitButton(),
                    bootstrap: new Bootstrap()
                }
            });
            this.fv.on("core.form.valid", () => {
                var _this = this;

                //按钮加载动画
                const submitButton = this.$refs["data_form_submit"];
                submitButton.classList.add("spinner", "spinner-light", "spinner-right");

                updateProudct(_this.product).then(function (res){
                    if (res) {
                        KTUtil.toast(_this, '结果提醒', '保存成功', 'success');
                        data_form.reset();

                        _this.$router.push({path: '/product'});
                    }
                    submitButton.classList.remove(
                        "spinner",
                        "spinner-light",
                        "spinner-right"
                    );
                });
            });
        },
        methods: {
            productImageUpload: function(){
                this.$refs['product_image_upload'].dispatchEvent(new MouseEvent('click'));
            },
            productImageUploadChange: function(){
                var _this = this;
                var files = this.$refs['product_image_upload'].files;

                //图片上传
                for(var i = 0;i < files.length;i++){
                    let formdata = new FormData()
                    formdata.set('file', files[i]);
                    formdata.set('dir', '/product');
                    //限制500KB以内的图片上传
                    if(files[i].size <= 1024 * 500) {
                        upload(formdata).then(function (res) {
                            if (res && res.url) {
                                _this.product.images.push(res.url);
                            }
                        });
                    } else {
                        KTUtil.toast(_this, '结果提醒', '第 ' + (i + 1) + ' 个图片大小超过500KB，上传失败！', 'warning');
                    }
                }
            },
            removeProductImage: function(image) {
                var _this = this;
                if(_this.product.images){
                    for(var i = 0;i < _this.product.images.length;i++){
                        if(_this.product.images[i] == image){
                            _this.product.images.splice(i, 1);
                        }
                    }
                }
            },
            //产品图片裁剪
            cropperProductImage: function(image, idx) {
                var _this = this;
                this.$nextTick(function () {
                    _this.crop_data.id = new Date().getTime();
                    _this.crop_data.image = image;
                });

            },
            productImageChange: function(result){
                var _this = this;
                if(result != '0') {
                    if(_this.product.images){
                        for(var i = 0;i < _this.product.images.length;i++){
                            if(_this.product.images[i] == _this.crop_data.image){
                                _this.product.images[i] = result;
                            }
                        }
                    }
                }
            },

            initSortable: function(){
                var _this = this;
                //图片拖动
                var el = document.getElementById('superbox-image-list')
                new Sortable(el, {
                    onEnd: function (evt) {
                        // 获取排序之后的data数据
                        _this.product.images.splice(evt.newIndex, 0, _this.product.images.splice(evt.oldIndex, 1)[0]);

                    }
                });
            },
            addAttrKey: function(){
                var value = this.$refs['add_attr_key_input'].value;
                if(value){
                    //判断属性是否存在
                    for(var i = 0;i < this.attr_keys.length;i++){
                        if(this.attr_keys[i].name == value){
                            KTUtil.toast(this, '提示', '该主题已经存在', 'danger');
                            return;
                        }
                    }

                    this.attr_keys.push({
                        'name': value
                    });
                }
            },
            addAttrValue: function(key){
                var value = KTUtil.getById('add_attr_value_input_'+ key).value;
                if(value){

                    //判断属性是否存在
                    for(var i = 0;i < this.attr_values.length;i++){
                        if(this.attr_values[i].name == value && this.attr_values[i].pname == key){
                            KTUtil.toast(this, '提示', '该属性已经存在', 'danger');
                            return;
                        }
                    }

                    this.attr_values.push({
                        'name': value,
                        'pname': key
                    });
                }
            },
            createSkuList: function(){

                //生成SKU列表
                var _this = this;
                var sku_list = [];

                //整理数组
                var array = [];
                if(_this.select_attr_values){
                    for(var key in _this.select_attr_values){
                        var arr = [];
                        for(var i = 0;i < _this.select_attr_values[key].length;i++){
                            var obj = {};
                            obj[key] = _this.select_attr_values[key][i];
                            arr.push(obj);
                        }
                        array.push(arr);
                    }
                }

                /*
                var array = [
                    [{'Color': 'Black'}, {'Color': 'White'}, {'Color': 'Red'}],
                    [{'Quantity': 1}, {'Quantity': 2}],
                    [{'Size': 'S'}, {'Size': 'L'}, {'Size': 'M'}]
                ];
                 */
                var result = [];
                result = _this.getCombination(array, result);

                var sku_list = [];
                if(result && result.length > 0){
                    result.forEach(function(value, index){

                        //设置默认SKU
                        var sku_str = '';
                        for(var k in value){
                            sku_str += value[k] + '-';
                        }
                        sku_str = sku_str.substring(0,sku_str.length - 1);

                        //保持原有的值不变
                        var obj = {'image_url': '', 'attr_info': value, 'sku': sku_str, 'price': '0.00', 'net_price': '0.00', 'discount': '0.00'};
                        if(_this.product.sku && _this.product.sku.length > 0){
                            _this.product.sku.forEach(function(sku_item){
                                if(KTUtil.CompareJsonObject(value, sku_item.attr_info)){
                                    obj['image_url'] = sku_item['image_url'];
                                    obj['sku'] = sku_item['sku'];
                                    obj['price'] = sku_item['price'];
                                    obj['discount'] = sku_item['discount'];
                                    obj['net_price'] = sku_item['net_price'];
                                }
                            });
                        }
                        sku_list.push(obj);
                    });
                }
                _this.$set(_this.product, 'sku', sku_list);
            },
            getCombination: function(array, result) {
                if (array.length === 0) {
                    return result;
                }

                //以第一条记录为基础开始递归添加属性值
                if (result.length === 0) {

                    //删除数组第一个元素并返回该元素
                    result = array.shift();
                    return this.getCombination(array, result);
                } else {
                    var arrItem = array.shift();
                    var emptyArray = [];
                    if (arrItem && arrItem.length > 0) {

                        result.forEach(function (item) {
                            arrItem.forEach(function (value) {
                                var obj = Object.assign({}, item, value);
                                emptyArray.push(obj);
                            });
                        });
                    } else {
                        emptyArray = result;
                    }
                    return this.getCombination(array, emptyArray);
                }
            },

            skuImageUploadChange: function(idx){
                var _this = this;
                var files = KTUtil.getById('sku_image_upload_' + idx).files;

                //图片上传
                for(var i = 0;i < files.length;i++){
                    let formdata = new FormData()
                    formdata.set('file', files[i]);
                    formdata.set('dir', '/product');
                    //限制500KB以内的图片上传
                    if(files[i].size <= 1024 * 500) {
                        upload(formdata).then(function (res) {
                            if (res && res.url) {
                                _this.product.sku.[idx].image_url = res.url;
                            }
                        });
                    } else {
                        KTUtil.toast(_this, '结果提醒', '第 ' + (i + 1) + ' 个图片大小超过500KB，上传失败！', 'warning');
                    }
                }
            },
            skuImageUploadClick: function(idx){
                KTUtil.getById('sku_image_upload_' + idx).dispatchEvent(new MouseEvent('click'));
            },
            deleteSkuItem: function(idx) {
                this.product.sku.splice(idx, 1);
            },
            batchProductSku(){
                this.dialog.id = new Date().getTime();
                this.dialog.show = true;
                this.dialog.title = 'SKU生成规则';
                this.dialog.view = batchAdd;
                this.dialog.data = {
                    operate: 'sku_add'
                }
            },
            batchProductPrice(){
                this.dialog.id = new Date().getTime();
                this.dialog.show = true;
                this.dialog.title = '批量生成价格';
                this.dialog.view = batchAdd;
                this.dialog.data = {
                    operate: 'price_add'
                }
            },
            batchProductDiscount(){
                this.dialog.id = new Date().getTime();
                this.dialog.show = true;
                this.dialog.title = '批量生成折扣';
                this.dialog.view = batchAdd;
                this.dialog.data = {
                    operate: 'discount_add'
                }
            },
            batchProductNetPrice(){
                this.dialog.id = new Date().getTime();
                this.dialog.show = true;
                this.dialog.title = '批量生成实价';
                this.dialog.view = batchAdd;
                this.dialog.data = {
                    operate: 'netprice_add'
                }
            },
            opeareResultHandler(result) {
                if(result){
                    if(result.operate == 'sku_add'){
                        if(this.product.sku && this.product.sku.length > 0){
                            for(var i = 0;i < this.product.sku.length;i++){
                                this.product.sku[i].sku = result.prevname + '-' + this.product.sku[i].sku + '-' + result.nextname;
                            }
                        }
                    } else {
                        var key = '';
                        if(result.operate == 'price_add') {
                            key = 'price';
                        } else if(result.operate == 'discount_add'){
                            key = 'discount';
                        } else if(result.operate == 'netprice_add'){
                            key = 'net_price';
                        }
                        if(this.product.sku && this.product.sku.length > 0){
                            for(var i = 0;i < this.product.sku.length;i++){
                                this.product.sku[i][key] = result.num;
                            }
                        }
                    }
                }
                this.dialog.show = false;
            },
            onFloatInput(e){
                e.target.value = (e.target.value.match(/^\d*(\.?\d{0,2})/g)[0]) || null;
            },
            selectCategory(val){
                this.product.category_name = val.category_name;
            }
        }
    }
</script>
